<template>
  <div class="d-flex align-center flex-wrap">
    <div
      v-if="isRangePagerVisible || isPagePagerVisible"
      class="text-caption pr-sm-3"
    >
      <!-- Range Pager -->
      <div v-if="isRangePagerVisible">
        Showing:
        <span class="font-weight-bold">
          {{ rangePager }}
        </span>
      </div>

      <!-- Page Pager -->
      <div v-else-if="isPagePagerVisible">
        <span v-if="itemDesignation">{{ pagePager }}:</span>
        <span v-else>{{ pagePager }}</span>

        <span v-if="itemDesignation" class="font-weight-bold">
          {{ itemDesignation }}
        </span>
      </div>
    </div>

    <v-pagination
      :value="value"
      :length="totalPages"
      :total-visible="totalVisible"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    totalVisible: {
      type: Number,
      default: 11,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 0,
    },
    itemsDesignation: {
      type: String,
      default: 'items',
    },
    itemDesignation: {
      type: String,
      default: '',
    },
    isRangePagerVisible: {
      type: Boolean,
      default: false,
    },
    isPagePagerVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rangePager() {
      const start = this.itemsPerPage * this.value - this.itemsPerPage + 1
      const x = this.totalItems - this.itemsPerPage * this.value

      if (x >= 0)
        return `${start}-${this.itemsPerPage * this.value} out of ${
          this.totalItems
        } ${this.itemsDesignation}`

      return `${start}-${this.totalItems} out of ${this.totalItems} ${this.itemsDesignation}`
    },
    pagePager() {
      return `${this.value} of ${this.totalPages} ${this.itemsDesignation}`
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-pagination {
  button {
    box-shadow: none !important;
    border: 1px solid var(--v-ap-dark-blue-base);
  }

  .v-pagination__navigation .v-icon {
    color: var(--v-ap-dark-blue-base);
  }

  .v-pagination__navigation--disabled {
    border: 1px solid var(--v-ap-gray-base);
    .v-icon {
      color: var(--v-ap-gray-base);
    }
  }
}
</style>
