<template>
  <v-dialog
    :value="true"
    overlay-opacity="0.8"
    persistent
    max-width="1200"
    width="100%"
    content-class="dupes-grid-dialog"
  >
    <div class="d-flex align-center">
      <v-btn
        v-if="hasMultipleRecords && !$msdcrm"
        :disabled="isPrevDisabled"
        icon
        x-large
        @click="prevRecord"
      >
        <v-icon size="48" color="white"> $mdi-chevron-left </v-icon>
      </v-btn>

      <v-sheet
        dark
        :color="$msdcrm ? 'white' : 'transparent'"
        class="w-full overflow-hidden"
      >
        <!-- header -->
        <div
          class="d-flex mb-2"
          :class="{ 'pa-4 ap-black--text': $msdcrm, 'align-center': !$msdcrm }"
        >
          <DupesGridDialogTitle
            :title="title"
            :current-dupe-set="currentRecord"
          />

          <v-spacer />

          <div class="flex-shrink-0 ml-3">
            <span v-if="hasMultipleRecords">
              {{
                `${currentRecordIndex + 1} of ${
                  records.length
                } ${paginationLabel}`
              }}
            </span>

            <v-btn icon class="ml-2 mr-n1" @click="$emit('close')">
              <v-icon color="ap-dark-gray" class="white pa-1 rounded-sm">
                $mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>

        <!-- content -->
        <v-card light class="w-full overflow-hidden">
          <DupesGridIframe
            v-if="currentRecord.id"
            :mode="mode"
            :dupe-set="currentRecord"
          />
        </v-card>

        <!-- footer -->
        <div class="d-flex align-center">
          <DupesGridDialogFooterDetails
            :items="footerDetails"
            class="d-flex flex-grow-1"
            :class="{
              'pa-4 ap-black--text': $msdcrm,
              'mt-3': !$msdcrm,
            }"
            :column-display="$msdcrm"
          />

          <DupesGridDialogFooterPagination
            v-if="hasMultipleRecords && $msdcrm"
            :is-prev-disabled="isPrevDisabled"
            :is-next-disabled="isNextDisabled"
            @prev="prevRecord"
            @next="nextRecord"
          />
        </div>
      </v-sheet>

      <v-btn
        v-if="hasMultipleRecords && !$msdcrm"
        :disabled="isNextDisabled"
        icon
        x-large
        @click="nextRecord"
      >
        <v-icon size="48" color="white"> $mdi-chevron-right </v-icon>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import DupesGridIframe from './DupesGridIframe'
import DupesGridDialogTitle from './DupesGridDialogTitle'
import DupesGridDialogFooterDetails from './DupesGridDialogFooterDetails'
import DupesGridDialogFooterPagination from './DupesGridDialogFooterPagination'

export default {
  components: {
    DupesGridIframe,
    DupesGridDialogTitle,
    DupesGridDialogFooterDetails,
    DupesGridDialogFooterPagination,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    footerDetails: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
    records: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'duplicate',
    },
    paginationLabel: {
      type: String,
      default: 'duplicate sets',
    },
  },
  data() {
    return {
      currentRecord: {},
      currentRecordIndex: null,
    }
  },
  computed: {
    hasMultipleRecords() {
      return this.records.length > 1
    },
    isPrevDisabled() {
      return this.hasMultipleRecords && this.currentRecordIndex <= 0
    },
    isNextDisabled() {
      return (
        this.hasMultipleRecords &&
        this.currentRecordIndex >= this.records.length - 1
      )
    },
  },
  watch: {
    currentRecord(val) {
      this.$emit('input', val)
    },
    currentRecordIndex(val) {
      this.currentRecord = this.records[val]
    },
  },
  mounted() {
    if (this.records.length > 0) this.currentRecordIndex = 0
  },
  methods: {
    nextRecord() {
      if (this.currentRecordIndex + 1 === this.records.length) {
        this.currentRecordIndex = 0
      } else {
        this.currentRecordIndex++
      }
    },
    prevRecord() {
      if (this.currentRecordIndex === 0) {
        this.currentRecordIndex = this.records.length - 1
      } else {
        this.currentRecordIndex--
      }
    },
  },
}
</script>

<style>
.dupes-grid-dialog {
  box-shadow: none !important;
  overflow: hidden;
}
</style>
