<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        v-if="textfield"
        class="white font-weight-bold"
        :value="formatDate(date)"
        :placeholder="placeholder || 'Date'"
        append-icon="$mdi-calendar-month"
        outlined
        dense
        hide-details
        v-on="on"
        @click:append="menu = true"
      />
      <v-icon v-else class="mr-1" v-on="on">
        $mdi-calendar-month-outline
      </v-icon>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      color="primary"
      :min="min"
      :max="max"
      @input="$refs.menu.save(date)"
    />
  </v-menu>
</template>

<script>
import { formatDate } from '@/utils/dates'

export default {
  props: {
    value: {
      type: String,
      default: new Date().toISOString().substr(0, 7),
    },
    textfield: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    formatDate,
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,
  }),
  watch: {
    value: function (val) {
      this.date = val
    },
    date: function (newVal, oldVal) {
      if (newVal && this.value !== newVal && newVal !== oldVal)
        this.$emit('input', newVal)
    },
  },
  mounted() {
    this.date = this.value
    this.$emit('input', this.date)
  },
  methods: {
    openMenu() {
      this.menu = true
    },
  },
}
</script>
