<template>
  <div class="pa-4">
    <v-btn
      outlined
      color="primary"
      class="mr-3"
      :disabled="isPrevDisabled"
      @click="$emit('prev')"
    >
      <v-icon left color="primary"> $mdi-chevron-left </v-icon>
      Previous record
    </v-btn>

    <v-btn
      outlined
      color="primary"
      :disabled="isNextDisabled"
      @click="$emit('next')"
    >
      Next record
      <v-icon right color="primary"> $mdi-chevron-right </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    isPrevDisabled: {
      type: Boolean,
      default: false,
    },
    isNextDisabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
